<template>
  <div :id="this.$route?.query?.isDarkTheme === 'true' ? 'dark-theme' : ''" class="container-fluid"
    :class="this.$route?.query?.isDarkTheme === 'true' ? 'bg-dark' : ''">
    <LoadingOverlay :isLoading="isLoadingWorkflows" />
    <!-- First Row -->

    <div>
      <WorkflowHeader :workflowName="workflow.name" :nodes="nodes" :isErrors="isErrors"
        :isSavingWorkflow="isSavingWorkflow" @back-to-workflows="backToWorkflows" @stepAdded="stepAdded += 1"
        @save-flow="saveFlow()" />

      <!-- Second Row -->
      <WorkflowTabs :queryParamsObject="queryParamsObject" :activeTab="activeTab" :parent_lib_id="parent_lib_id"
        :is_enabled="is_enabled" @changeTab="activeTab = $event" @is_enabled="is_enabled = $event"
        @stepAdded="stepAdded += 1" />

      <!-- Third Row -->
      <div class="row basicflow" v-show="activeTab == 'builder'">
        <div class="col-12" :class="isOpen ? 'col-12' : ''">
          <div class="text-sm p-3 pt-0 text-center"></div>
          <WorkflowViewport :isOpen="isOpen" :renderKey="renderKey" :stepAdded="stepAdded" :activeTab="activeTab"
            :workflowNodes="nodes" :workflowEdges="edges" :workflowJson="workflow" :isErrors="isErrors"
            :instance="instance" @nodes="nodes = $event" @workflow="workflow = $event" @isOpen="isOpen = $event"
            @renderKey="renderKey += 1" @centerAllTriggers="centerAllTriggers()" @stepAdded="stepAdded += 1"
            @onPaneReady="onPaneReady($event)" @resetFlow="resetFlow()" @isErrors="isErrors = $event"
            :support_lib_id="support_lib_id" :isLoadingWorkflows="isLoadingWorkflows" :parent_lib_id="parent_lib_id"
            :ref_uid="ref_uid" :ticket_statuses="ticket_statuses" @selectedFlow="selectedFlow = $event"
            @connectEdges="connectEdges()" />
        </div>
      </div>

      <div v-show="activeTab == 'settings' && !parent_lib_id">
        <WorkflowSettings :isErrors="isErrors" :run_for_child="run_for_child" :childAgenciesList="childAgenciesList"
          :isChildAgenciesLoading="isChildAgenciesLoading" :child_agencies="child_agencies"
          @isErrors="isErrors = $event" @change_run_for_child="run_for_child = $event"
          @child_agencies="child_agencies = $event" />
      </div>

      <div v-show="['executionhistory', 'executionlogs'].includes(activeTab)">
        <WorkflowExecutionLogs :queryParamsObject="queryParamsObject" :nodes="nodes" :isErrors="isErrors"
          :activeTab="activeTab" :run_for_child="run_for_child" :childAgenciesList="childAgenciesList"
          :isChildAgenciesLoading="isChildAgenciesLoading" :child_agencies="child_agencies"
          @isErrors="isErrors = $event" @activeTab="activeTab = $event" @change_run_for_child="run_for_child = $event"
          @child_agencies="child_agencies = $event" />
      </div>
    </div>
  </div>
</template>

<script>
  import '@/assets/style-workflow.css';
  import Api from '../Api';
  import LoadingOverlay from './LoadingOverlay.vue';
  import WorkflowHeader from './WorkflowHeader.vue';
  import WorkflowTabs from './WorkflowTabs.vue';
  import WorkflowSettings from './WorkflowSettings.vue';
  import WorkflowViewport from './WorkflowViewport.vue';
  import WorkflowExecutionLogs from './WorkflowExecutionLogs.vue';
  import moment from 'moment';
  import { useToast } from 'vue-toastification';
  import CustomToast from '@/components/customToast.vue';

  export default {
    name: 'ManageWorkflow',
    components: {
      LoadingOverlay,
      WorkflowHeader,
      WorkflowTabs,
      WorkflowSettings,
      WorkflowViewport,
      WorkflowExecutionLogs,
    },
    setup() {
      const toast = useToast();
      return {
        toast,
      };
    },

    data() {
      return {
        selectedFlow:'',
        defaultXPosition: 450,
        activeTab: this.$route?.query?.activetab || 'builder',
        isChildAgencyPanelOpen: false,
        isSavingWorkflow: false,
        isLoadingWorkflows: false,
        support_lib_id: this.$route.query.support_lib_id,
        parent_lib_id: this.$route.query.parent_lib_id,
        ref_uid: this.$route.query.user_id,

        isErrors: false,

        ticket_statuses: [],

        companies: [],
        selectedChilds: [],
        isChildAgenciesLoading: false,
        run_for_child: '',
        child_agencies: [],
        childAgenciesList: [],
        flowSave: false,
        instance: null,
        is_enabled: false,

        workflow: {
          id: this.$route.query.automation_ref,
          name: '',
          trigger: {
            events: [],
          },
          branches: [
            {
              actions: [],
              branches: [],
            },
          ],
        },
        renderKey: 0,
        stepAdded: 0,
        isOpen: false,
        nodes: [
          { id: '1', type: 'custom', label: 'Add New Triggers', position: { x: 450, y: 0 }, data: { type: 'addNewTrigger', add: true, icon: 'fa fa-plus' } },
          { id: '2', type: 'custom', label: 'Add Action', position: { x: 450, y: 125 }, data: { type: 'addNewAction', add: true, icon: 'fa fa-plus' } },
          { id: '3', type: 'custom', label: 'END', position: { x: 450, y: 250 }, data: { label: 'END', type: 'EndTrigger', add: true } },
        ],
        edges: [
          { id: 'e1-2', source: '1', type: 'smoothstep', target: '2', animated: false },
          { id: 'e1-3', source: '2', type: 'smoothstep', target: '3', animated: false },
        ],
        queryParamsObject:this.$route.query || {}
      };
    },
    mounted() {
      this.getWorkflows();
      Api.getTicketStatuses(this.support_lib_id, this.$route.query.token)
      .then((res) => {
        if (res?.data?.ticket_statuses) {
          this.ticket_statuses = res.data.ticket_statuses;
        }
      })
      .catch((err) => {
        console.log('error', err);
      });
      window.addEventListener("message", (event) => {
        // Check if the message is intended for setting the data_alert
        // console.log("event.data.data_alert ", event.data.data_alert);
        if (event.data.action === "parentQueryParams") {
          this.queryParamsObject = event.data.query;
        }
      });
      this.fetchChildAgencies();
      this.toggleModalTheme()
      if (this.$route?.query?.isDarkTheme === 'true') {
        document.body.classList.add('dark-theme');
      } else {
        document.body.classList.remove('dark-theme');
      }
    },
    methods: {
      calculateChildPositions(parentX, nodeWidth, branches) {
        const totalWidth = (branches - 1) * (nodeWidth / 2) + branches * nodeWidth;
        const startX = parentX - (totalWidth - nodeWidth) / 2;
        const positions = [];
        for (let i = 0; i < branches; i++) {
          const childX = startX + i * (nodeWidth + nodeWidth / 2);
          positions.push(childX);
        }
        return positions;
      },
      centerAllTriggers() {
        let triggers = this.nodes.filter((n) => n.data.type === 'addNewTrigger');
        let positions = this.calculateChildPositions(this.defaultXPosition, 250, triggers.length);
        triggers.forEach((el, i) => {
          el.position.x = positions[i];
        });
      },
      connectEdgesAndDescendants(addActionNodesParentsOnly) {
        addActionNodesParentsOnly.forEach((el, i) => {
          if (i !== addActionNodesParentsOnly.length - 1) {
            this.edges.push({
              id: `e-${el.id}-${addActionNodesParentsOnly[i + 1].id}`,
              type: 'smoothstep',
              source: el.id,
              target: addActionNodesParentsOnly[i + 1].id,
            });
          }
          if (el.name == 'condition') {
            el.branches.forEach((branch) => {
              const childBranches = this.nodes.filter((elem) => elem.data.branchId == branch.id);
              this.connectEdgesAndDescendants(childBranches);
              this.edges.push({
                id: `e-${el.id}-${childBranches[0].id}`,
                type: 'smoothstep',
                source: el.id,
                target: childBranches[0].id,
              });
            });
          }
        });
      },
      connectEdges() {
        this.edges = [];
        let addActionNodesParentsOnly = this.nodes.filter((n) => (n.data.type === 'addNewAction' || n.data.type === 'EndTrigger') && !n.data.branchId);
        const firstAddActionNode = addActionNodesParentsOnly[0];
        const triggerNodes = this.nodes.filter((n) => n.data.type === 'addNewTrigger');
        triggerNodes.forEach((triggerNode) => {
          this.edges.push({
            id: `e-${triggerNode.id}-${firstAddActionNode.id}`,
            type: 'custom',
            source: triggerNode.id,
            target: firstAddActionNode.id,
          });
        });
        this.connectEdgesAndDescendants(addActionNodesParentsOnly);
        this.nodes.forEach((el) => {
          if (el?.name == 'condition') {
            el.branches.forEach((branch) => {
              const childBranches = this.nodes.filter((el) => el.data.branchId == branch.id);
              // console.log('childBranches ', childBranches)
              childBranches.forEach((child, i) => {
                if (i !== childBranches.length - 1) {
                  this.edges.push({
                    id: `e-${child.id}-${childBranches[i + 1].id}`,
                    type: 'custom',
                    source: child.id,
                    target: childBranches[i + 1].id,
                  });
                }
              });
            });
          }
        });
      },

      saveFlow() {
        if (this.isOpen) {
          this.$store.state.showToast = {
            icon: 'fa fa-exclamation-triangle',
            title: `Attention`,
            description: `Please complete and save your ${this.selectedFlow == 'addNewTrigger' ? 'trigger' : 'action'} first`,
            time: moment().valueOf(),
          };
          return;
        }
       
        this.isSavingWorkflow = true;
        let workflowName = document.getElementById('workflowName');
        this.workflow.name = workflowName.innerText;
        let payload = {
          is_enabled: this.is_enabled ? 1 : 0,
          support_lib_id: this.support_lib_id,
          ref_uid: this.ref_uid,
          workflow_name: this.workflow.name,
          workflow_obj: {
            workflow: this.workflow,
          },
          workflow_setup: {
            nodes: this.nodes,
            edges: this.edges,
          },
        };

        if (!this.parent_lib_id) {
          payload = {
            ...payload,
            run_for_child: this.run_for_child,
            child_agencies: this.child_agencies,
          };
        }

        // Helper function to check for mismatched brackets in a string
        const hasMismatchedBrackets = (str) => {
          const stack = [];
          const bracketPairs = {
            '}': '{',
            ']': '[',
          };

          for (let char of str) {
            if (char === '{' || char === '[') {
              stack.push(char);
            } else if (char === '}' || char === ']') {
              const lastBracket = stack.pop();
              if (lastBracket !== bracketPairs[char]) {
                return true; // Mismatched closing bracket found
              }
            }
          }

          // If stack is not empty, it means there are unmatched opening brackets
          return stack.length > 0;
        };

        // Main function to check if the object is valid JSON and check for mismatched brackets in strings
        const isValidJSONObjectWithBracketCheck = (obj) => {
          try {
            // First, check if the object can be serialized
            JSON.stringify(obj);

            // Function to recursively search through the object for strings with mismatched brackets
            const checkBracketsInObject = (o) => {
              for (const key in o) {
                if (typeof o[key] === 'string') {
                  if (hasMismatchedBrackets(o[key])) {
                    return false; // Return false if mismatched brackets are found
                  }
                } else if (typeof o[key] === 'object' && o[key] !== null) {
                  if (!checkBracketsInObject(o[key])) {
                    return false; // Recursively check nested objects
                  }
                }
              }
              return true; // No mismatched brackets found
            };

            // Perform the bracket check
            return checkBracketsInObject(obj);
          } catch (e) {
            return false; // Invalid JSON
          }
        };

        if (!isValidJSONObjectWithBracketCheck(payload)) {
          this.$store.state.showToast = {
            icon: 'fa fa-exclamation-triangle',
            title: `Error`,
            description: 'Your Automation is not valid',
            time: moment().valueOf(),
          };
          this.isSavingWorkflow = false;
          return;
        }

        Api.updateWorkflow(payload, { workflow_ref: this.$route.query.automation_ref }, this.$route.query.token)
          .then((res) => {
            this.$store.state.showToast = {
              icon: 'fa fa-check',
              title: `Success`,
              description: res?.data?.message || 'Automation updated successfully',
              time: moment().valueOf(),
            };
          })
          .catch((err) => {
            console.log('error while saving workflow :- ', err);
            this.$store.state.showToast = {
              icon: 'fa fa-exclamation-triangle',
              title: `Error`,
              description: 'An error occured while saving automation',
              time: moment().valueOf(),
            };
          })
          .finally(() => {
            this.isSavingWorkflow = false;
            this.stepAdded = 0;
            this.fitViewNodes();
          });
      },
      resetFlow() {
        this.nodes = [
          { id: '1', type: 'custom', label: 'Add New Triggers', position: { x: 450, y: 0 }, data: { type: 'addNewTrigger', add: true, icon: 'fa fa-plus' } },
          { id: '2', type: 'custom', label: 'Add Action', position: { x: 450, y: 125 }, data: { type: 'addNewAction', add: true, icon: 'fa fa-plus' } },
          { id: '3', type: 'custom', label: 'END', position: { x: 450, y: 250 }, data: { label: 'END', type: 'EndTrigger', add: true } },
        ];
        this.edges = [
          { id: 'e1-2', source: '1', type: 'custom', target: '2', animated: false },
          { id: 'e1-3', source: '2', type: 'custom', target: '3', animated: false },
        ];
      },
      onPaneReady(vueFlowInstance) {
        vueFlowInstance.fitView();
        this.instance = vueFlowInstance;
      },
      backToWorkflows() {
        let redirecturl = this.$route?.query?.redirecturl;
        if (this.stepAdded === 0) {
          window.parent.postMessage(
            { type: 'redirect', route:redirecturl ? decodeURIComponent(redirecturl) : '/admin/automation' }, 
            '*'
          );
        } else {
          this.$swal({
            title: 'Attention!',
            text: `Your unsaved changes will be lost. Do you still want to proceed?`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Confirm',
            customClass: {
              confirmButton: 'btn btn-dark me-2',
              cancelButton: 'btn btn-danger',
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.isConfirmed) {
              window.parent.postMessage(
                { type: 'redirect', route:redirecturl ? decodeURIComponent(redirecturl) : '/admin/automation' }, 
                '*'
              );
            }
          });
        }


      },
      fetchChildAgencies() {
        if (!this.parent_lib_id) {
          this.isChildAgenciesLoading = true;
          let params = { support_lib_id: this.support_lib_id };
          Api.listChildLibraries(params, this.$route.query.token)
            .then((res) => {
              if (res?.data?.agency_list) {
                this.childAgenciesList = res?.data?.agency_list;
              }
            })
            .catch((err) => {
              console.log('An error occurred while fetching child libraries :- ', err);
            })
            .finally(() => {
              this.isChildAgenciesLoading = false;
            });
        }
      },
      getWorkflows() {
        // console.log('this.$route ', this.$route?.query);
        if (!this.$route?.query?.automation_ref) {
          return;
        }
        this.isLoadingWorkflows = true;
        Api.getWorkflows({ support_lib_id: this.support_lib_id, workflow_ref: this.$route.query.automation_ref }, this.$route.query.token)
          .then((res) => {
            let response = res?.data?.workflows[0];
            if (response) {
              (this.workflow.name = response?.workflow_name), (this.is_enabled = !!response.is_enabled);
              // console.log(' this.is_enabled  ',  this.is_enabled )
              let workflowSetup = JSON.parse(response?.workflow_setup);
              let workflowObj = JSON.parse(response?.workflow_obj);
              // console.log('workflowSetup?.nodes ', workflowSetup?.nodes);
              if (workflowSetup?.nodes) {
                this.nodes = workflowSetup.nodes;
              }
              if (workflowSetup?.edges) {
                this.edges = workflowSetup.edges;
              }
              if (workflowObj?.workflow) {
                this.workflow = workflowObj?.workflow;
              }
              if (!this.parent_lib_id) {
                this.run_for_child = response?.run_for_child || '';
                this.child_agencies = response?.child_agencies || [];
              }
            }
          })
          .catch((err) => {
            console.error('Error while fetching workflows :- ', err);
          })
          .finally(() => {
            this.isLoadingWorkflows = false;
            setTimeout(() => {
              this.stepAdded = 0
              this.fitViewNodes();
            }, 10);
          });
      },
      fitViewNodes() {
        let findNodes = this.nodes.find((el) => !el.data.add);
        let findActionNodes = this.nodes.find((el) => el.data.type == 'addNewAction' && !el.data.add);
        if (!findActionNodes) {
          this.instance.fitView(this.$store.state.fitViewParams);
        } else if (!findNodes) {
          this.instance.fitView({ padding: 0.25, offset: { x: 0, y: -100 }, duration: 500 });
        } else {
          this.instance.fitView({ padding: 0.2 });
        }
      },
      toggleModalTheme() {
        const head = document.head;
        const existingLink = document.getElementById('modaldarkuiautomation');
        const isDarkTheme = this.$route?.query?.isDarkTheme === 'true';
        if (isDarkTheme) {
          if (!existingLink) {
            const link = document.createElement('link');
            link.type = 'text/css';
            link.rel = 'stylesheet';
            link.id = 'modaldarkuiautomation';
            link.href = '/darkmodalui.css';
            head.appendChild(link);
          }
        } else {
          if (existingLink) {
            head.removeChild(existingLink);
          }
        }
      }
    },
    watch: {
      '$route?.query?.isDarkTheme === "true"': function (newVal) {
        if (newVal) {
          document.body.classList.add('dark-theme');
        } else {
          document.body.classList.remove('dark-theme');
        }
      }, 
      '$store.state.showToast': {
        deep: true,
        handler() {
          this.toast(
            {
              component: CustomToast,
              props: {
                title: this.$store.state.showToast.title,
                description: this.$store.state.showToast.description,
              },
            },
            {
              position: 'top-right',
              timeout: 3000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: 'button',
              icon: this.$store.state.showToast.icon,
              rtl: false,
            }
          );
        },
      },
      isChildAgencyPanelOpen() {
        if (this.isChildAgencyPanelOpen) {
          document.body.classList.add('childAgencyPanelOpen');
        } else {
          document.body.classList.remove('childAgencyPanelOpen');
        }
      },
    },
  };
</script>

<style scoped>
  .container-fluid {
    padding: 0;
  }

  .row {
    margin: 0;
  }

  .row > div {
    padding: 0 15px;
  }

  .form-check-label {
    display: inline-block;
    margin-right: 5px;
  }

  .form-check-input {
    margin-right: 5px;
  }

  .border-light {
    border-color: var(--gray-200) !important;
  }

  .nav-pills .nav-link.active {
    /* background-color: var(--primary-color) !important; */
    outline: none;
  }

  .nav-pills .nav-link {
    color: var(--base-black) !important;
    background-color: var(--base-white) !important;
    border-bottom: 2px solid var(--base-white);
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 10px;
  }

  .text-sm {
    font-size: 0.925rem !important;
  }

  .form-check-label {
    /* font-size: 0.925rem !important; */
    outline: none;
  }

  .form-switch {
    padding-left: 1.25em !important;
  }

  .custom-link {
    color: #333 !important;
    text-decoration: none !important;
    font-weight: normal !important;
  }

  .custom-link:hover {
    color: black !important;
    text-decoration: none !important;
  }

  .basicflow {
    background: radial-gradient(circle, var(--gray-200) 2px, transparent 1.5px) 0 0 / 20px 20px repeat;
    background-color: var(--primary-bg-body);
    height: calc(100vh - 100px);
    width: 100%;
    position: relative;
  }

  .btn-light {
    background-color: var(--primary-50);
    color: var(--primary-color);
    border: var(--primary-50);
  }

  .btn-light:hover {
    background-color: var(--primary-100);
    border: var(--primary-200);
    color: var(--primary-color);
  }

  .btn-white {
    background-color: var(--base-white);
    color: var(--base-black);
    border-color: var(--gray-200);
  }

  .btn-white:hover {
    color: var(--primary-600);
    background-color: var(--base-white);
    border-color: var(--gray-200);
  }

  .btn-primary {
    background-color: var(--primary-600) !important;
    color: var(--base-white) !important;
    border-color: var(--primary-600) !important;
  }

  .controlbuttons {
    bottom: 20px !important;
    left: 20px !important;
  }

  .form-switch .form-check-input {
    width: 2.75rem !important;
    height: 1.275rem !important;
  }

  #app .bg-light {
    background-color: var(--base-white) !important;
  }

  .shadow-sm {
    box-shadow: 0 8px 26px -4px rgba(20, 20, 20, 0.03), 0 8px 9px -5px rgba(20, 20, 20, 0.04) !important;
  }

  .form-switch .form-check-input {
    width: 2.5rem !important;
  }
  .Vue-Toastification__toast--default {
    background-color: #222222;
  }

  .btn-white-center {
    border-radius: 0px !important;
    border-left-color: var(--base-white);
    border-right-color: var(--base-white);
  }
</style>
